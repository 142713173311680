/**
 * 品牌馆-广告管理
 * luxinwen
 * 2023-04-17
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">小程序启动视频</div>
        <div class="padding-b-20">
          <sp-upload v-model="formData.videoUrlStrings" :max-size="51200" :format="['MP4', 'WebM', 'Ogg']" video></sp-upload>
          <p class="text-tips">为保证播放效果，请尽量上传宽高比为1:2的视频</p>
        </div>
        <div class="page-main-content-thead">品牌馆首页顶部轮播图</div>
        <div class="padding-b-20">
          <sp-upload v-model="formData.picUrlStrings" :img-width="750" :img-height="1280" move :before-upload="beforeUpload"></sp-upload>
          <p class="text-tips">请上传宽高为750*1280的图片</p>
        </div>
      </div>
    </div>
    <sp-footer>
      <Button type="primary" :loading="btnLoading" @click="submit">提交</Button>
      <Button @click="cancel">返回</Button>
    </sp-footer>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        btnLoading: false,  // 按钮状态
        formData: {
          ownerId: -1,  // 楼盘或户型id
          picUrlStrings: [],  // 图集合，英文逗号分割
          videoUrlStrings: '',  // 视频集合，英文逗号分割
        }
      };
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.project.queryPhotoList,
          data: {
            ownerId: this.formData.ownerId
          },
          loading: true
        }).then(data => {
          let list = data || [];
          let videoUrlStrings = list.filter(item => item.mediaType === 2);
          if (videoUrlStrings.length > 0) {
            this.formData.videoUrlStrings = videoUrlStrings[0].url;
          }
          this.formData.picUrlStrings = list.filter(item => item.mediaType === 1);
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$confirm('确定要提交吗？').then(() => {
          let url = this.$api.project.addPhoto;
          let data = Object.assign({}, this.formData);
          data.picUrlStrings = data.picUrlStrings.map(item => item.url).join(',');
          this.save(url, data);
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.btnLoading = true;
          },
          complete: () => {
            this.btnLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
        });
      },
      /**
       * 返回
       */
      cancel() {
        this.$router.go(-1);
      },
      /**
       * 图片上传限制
       */
      beforeUpload(file) {
        if (this.formData.picUrlStrings.length >= 10) {
          this.notice('轮播图最多只能上传10张');
          return false;
        }
      }
    }
  };
</script>